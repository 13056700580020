import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { HubConnectionBuilder, HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import config from '../config/config';
import { BroadcastChannel } from 'broadcast-channel';
import useNuevaPeticion from '../components/Petiticiones/Nuevo/hooks/useNuevapeticion';
import useDirecciones from '../components/hooks/useDirecciones';
const AuthContext = createContext();
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

// Proveedor del contexto de autenticación
export const AuthProvider = ({ children }) => {
  // VARIABLE DE USUARIO
  const [userId, setUserId] = useState(null);
  const [token, settoken] = useState(localStorage.getItem('Token'))
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [cancelToken, setCancelToken] = useState(null);
  const logoutChannel = new BroadcastChannel('logout');
  const loginChanenel = new BroadcastChannel('login');
  //prueba guias
  const [idGuia, setidGuia] = useState()
  const [pendingGuide, setpendingGuide] = useState()
  const [nameGuia, setnameGuia] = useState()
  const [vistaAnterior, setVistaAnterior] = useState("");

const actualizarVistaAnterior = (vista) => {
    setVistaAnterior(vista);
};
  const [infoGuia, setinfoGuia] = useState(
  [
    {
      "idCliente": 4593,
      "nombreCliente": "Juan Manuel Quiñonez Parra",
      "idSucursal": 1,
      "nombreSucursal": "LMM Centro",
      "ciudad": "Los Mochis",
      "telefono": 666666666,
      "envios": {
        "Pendientes": {
          "prioritarios": 3,
          "normales": 7,
          "total": 10
        }
      }
    },
    {
      "idCliente": 680,
      "nombreCliente": "Manuel Quiñonez Parra",
      "idSucursal": 3,
      "nombreSucursal": "MTY Valles",
      "ciudad": "Monterrey",
      "telefono": 7777777777,
      "envios": {
        "Pendientes": {
          "prioritarios": 0,
          "normales": 8,
          "total": 9
        }
      }
    }
  ])
  const SeleccionarGuiaPendiente = (res) => {
    setidGuia(res.idCliente)
    setnameGuia(res.nombreCliente)
    setpendingGuide("Ecommerce")
  }
  const limpiarNameGuia = () => {
    setnameGuia('')
    setpendingGuide("")
    setidGuia('')
  }
  console.log(idGuia, "idGuia")
  //resolucion
  const [resolution, setResolution] = useState({ width: 0, height: 0 });
  let refreshInterval;
  useEffect(() => {
    function updateResolution() {
      setResolution({ width: window.innerWidth, height: window.innerHeight });
    }

    // Agregar un event listener para actualizar la resolución cuando cambie el tamaño de la ventana
    window.addEventListener('resize', updateResolution);

    // Obtener la resolución inicial al cargar el componente
    updateResolution();

    // Limpieza: remover el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', updateResolution);
    };
  }, []);
  // REFRESH

  const RefreshTokenFunction = async () => {
    // console.log("Entro a Refresh")
    const URLREFRESH = `${config.API_URL}Login/RefreshToken`
    var Requesttoken = JSON.stringify({
      "token": JSON.parse(localStorage.getItem("Token")),
      "refreshToken": JSON.parse(localStorage.getItem("Refreshtoken"))
    });
    try {
      const ResponseRefresh = await axios.post(URLREFRESH, Requesttoken, {
        headers: {
          'Content-Type': 'application/json',
        },
      })


      settoken(ResponseRefresh.data.token)
      localStorage.setItem("Token", JSON.stringify(ResponseRefresh.data.token))
      localStorage.setItem("Refreshtoken", JSON.stringify(ResponseRefresh.data.refreshToken))

    } catch (error) {
      // console.error(error, "ERROr 2")
    }

  }
  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      const expirationDate = new Date(decodedToken.exp * 1000);
      const currentTime = new Date();
      const refreshThreshold = 5 * 60 * 1000;

      if (expirationDate - currentTime < refreshThreshold) {

        // alert("HOLA");
        RefreshTokenFunction()

        // Realiza la lógica de actualización del token aquí
        // Luego, actualiza el token almacenado usando setToken
        // Detén el intervalo cuando actualices el token
        clearInterval(refreshInterval);
      } else {
        // Crea un intervalo para verificar el tiempo de expiración
        refreshInterval = setInterval(() => {
          const currentTime = new Date();
          if (expirationDate - currentTime < refreshThreshold) {
            RefreshTokenFunction()

            // alert("HOLA REFRESH");
            // Realiza la lógica de actualización del token aquí
            // Luego, actualiza el token almacenado usando setToken
            // Detén el intervalo cuando actualices el token
            clearInterval(refreshInterval);
          }
        }, refreshThreshold / 2); // Puedes ajustar el intervalo según tus necesidades
      }
    } else {

    }

    return () => {
      // Limpia el intervalo al desmontar el componente
      clearInterval(refreshInterval);
    };
  }, [token]);


  // SOCKET SIGNAL R
  const [socket, setSocket] = useState();  // SOCKET SIGNAL
  const intervalRef = useRef(null);
  const [ConexionSocket, setConexionSocket] = useState(localStorage.getItem("conexionSignalR"))

  // CAMBIO DE SUCURSAL
  const [CiudadSeleccionada, setCiudadSeleccionada] = useState(JSON.parse(localStorage.getItem('Ciudad')));

  // MENU
  const [isActiveMenu, setisActiveMenu] = useState(localStorage.getItem('lastmenu'));
  const [isActiveSubmenu, setActiveSubmenu] = useState(true);

  // CORDENADA DE MAPA
  const [coordenadamapa, setcoordenadamapa] = useState(JSON.parse(localStorage.getItem("coordenadamapa")))
  // FUNCIONES SOCKET
  const StartSocket = async () => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${config.Socket_URL}?userId=${user?.id}&origin=monitor`, {
        withCredentials: false,
        transport: HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect()
      .build();
    connect.serverTimeoutInMilliseconds = 10000;
    setSocket(connect);
    await connect
      .start()
      .then(() => {

        setConexionSocket(true)
        localStorage.setItem('conexionSignalR', true)
      })
      .catch((error) => {
        // console.error(error, "ERROR SOCKET")
      });
    connect.onclose(async () => {

      setConexionSocket(false)
      IntervalConexion()
      localStorage.setItem('conexionSignalR', false)
    })
  }
  const StopSocket = async () => {
    if (socket && socket.state !== 'disconnected') {
      await socket.stop();

    }
  }
  const ReconectedSocket = async (pararciclo) => {
    if (!socket) return;
    if (localStorage.getItem("conexionSignalR") === "false") {
      StartSocket()
    }
  }

  const IntervalConexion = async () => {
    intervalRef.current = setInterval(() => {
      if (localStorage.getItem("conexionSignalR") === "false") {
        ReconectedSocket(intervalRef.current)
      } else {
        clearInterval(intervalRef.current)
      }
    }, 5000);
  }
  // INICIAR SOCKET
  useEffect(() => {
    if (user?.id) {
      StartSocket()
    }
  }, [user?.id])
  //FUNCIONES CAMBIO DE CIUDAD
  const HandlerCambioCiudad = async (data) => {
    setisActiveMenu("inicio")
    await setCiudadSeleccionada({ idciudad: data.id, nombreciudad: data.nombre })
    await localStorage.setItem("Ciudad", JSON.stringify({ idciudad: data.id, nombreciudad: data.nombre }))
    let datacyty = {
      lng: data.longitud,
      lat: data.latitud,
      zoom: 10.5
    }
    localStorage.setItem("coordenadamapa", JSON.stringify(datacyty))
    localStorage.setItem("lastmenu", isActiveMenu)
  }
  // MENU
  const handleractiveMenu = (data) => {
    setisActiveMenu(data)

    localStorage.setItem("lastmenu", data)
  }
  const handleractiveSubMenu = (data) => {
    setActiveSubmenu(data)

  }
  // LLENADO DE VARIABLES DE MEMORIA
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('Ciudad', JSON.stringify(CiudadSeleccionada));
    localStorage.setItem("coordenadamapa", JSON.stringify(coordenadamapa))
  }, [user])


  // Función para realizar la autenticación
  const login = async (e, username, password) => {
    e.preventDefault();
    try {
      // Aquí realizarías la llamada a la API de login
      setLoading(true);
      setError(null);

      // Cancelar la solicitud anterior (si existe)
      if (cancelToken) {
        cancelToken.cancel('Solicitud cancelada');
      }

      const newCancelToken = axios.CancelToken.source();
      setCancelToken(newCancelToken);

      setTimeout(() => {
        newCancelToken.cancel('Tiempo de espera excedido');
      }, 5000);

      try {
        const response = await axios.post(`${config.API_URL}Login/login?Origin=Interno`, {
          "nombreUsuario": username,
          "pass": password,
          cancelToken: newCancelToken.token,
        });

        // Aquí puedes manejar la lógica de éxito del inicio de sesión
        const responseuser = await axios.get(`${config.API_URL}Usuario/getuser/${response.data.idusuario}`, { cancelToken: newCancelToken.token })
        const responseSuc = await axios.get(`${config.API_URL}Sucursal/getBranch/${responseuser.data.idfranquicia}`, { cancelToken: newCancelToken.token })
        const responseciudad = await axios.get(`${config.API_URL}Ciudad/Get/${responseSuc.data.idciudad}`, { cancelToken: newCancelToken.token })

        const datauser = {
          "id": responseuser.data.idusuario,
          "descripcion": responseuser.data.descripcion,
          "idsucursal": responseuser.data.idfranquicia,
          "sucursal": responseSuc.data.nombre,
          "idciudad": responseSuc.data.idciudad,
          "colonia": responseSuc.data.colonia,
          "cp": responseSuc.data.cp,
          "nombre": responseuser.datanombreusuario,
          "token": response.data.token,
          "refreshToken": response.data.refreshToken,
        }
        let datacity = {
          lng: responseciudad.data.longitud,
          lat: responseciudad.data.latitud,
          zoom: 10.5
        }
        localStorage.setItem("coordenadamapa", JSON.stringify(datacity))
        setisActiveMenu("inicio")
        localStorage.setItem('auth', true);
        setCiudadSeleccionada({ idciudad: responseciudad.data.id, nombreciudad: responseciudad.data.nombre })
        localStorage.setItem("Ciudad", JSON.stringify({ idciudad: responseciudad.data.id, nombreciudad: responseciudad.data.nombre }))
        localStorage.setItem("lastmenu", "inicio")
        localStorage.setItem("user", JSON.stringify(datauser))
        localStorage.setItem("Token", JSON.stringify(response.data.token))
        localStorage.setItem("Refreshtoken", JSON.stringify(response.data.refreshToken))

        loginChanenel.postMessage("Login")

      } catch (error) {
        console.error(error.response.data, "ERROR")
        if (axios.isCancel(error)) {
          console.error('Solicitud cancelada');
        } else {
          setError('Error al iniciar sesión. Por favor, verifica tus credenciales.');
        }
      } finally {
        setLoading(false);
        setCancelToken(null);
        window.location.reload(true)
      }
      // setIsLoggedIn(true);
    } catch (error) {
      console.error('Error de autenticación:', error);
    }
  };
  // Función para realizar el cierre de sesión

  const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      logout();
      logoutChannel.close();
    }
  }

  const logout = () => {
    let Version = localStorage.getItem(1)
    // Realizar cualquier lógica necesaria para cerrar la sesión
    logoutChannel.postMessage("Logout")
    localStorage.clear();
    localStorage.setItem("lastmenu", "login")
    localStorage.setItem(1, Version);
    window.location.reload(true)


  };

  // SOCKET
  const [RecolectoresMapa, setRecolectoresMapa] = useState([])
  const handleaddReco = (data) => {
    if (data || data == []) {
      setRecolectoresMapa(...RecolectoresMapa, data)
    }

  }
// console.log(RecolectoresMapa)
  useEffect(() => {
    if (!socket) return;
    socket.on("ActivePickers", (marcadores) => {

      handleaddReco(marcadores)
    });

  }, [socket])
  const HandleEditReco = (id, lat, lng, estatus, RECOS) => {

    const NewArrayReco = RECOS?.map((r, index) => {

      if (r.id == id) {
        r.lat = lat
        r.lng = lng
        // r.status = estatus
      }
      return r
    })

    // handleaddReco(NewArrayReco)
  }

  const FormatoPeso = (data) => {
    if(data != undefined){
      const parts = data?.toFixed(2).toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    }
  }


  // Valor del contexto
  const authContextValue = {
    login,
    logout,
    user,
    loading,
    StartSocket,
    socket,
    StopSocket,
    RecolectoresMapa,
    HandleEditReco,
    handleractiveMenu,
    ConexionSocket,
    HandlerCambioCiudad,
    logoutAllTabs,
    resolution,
    FormatoPeso,
    RefreshTokenFunction,
    CiudadSeleccionada,
    infoGuia,
    setinfoGuia,
    SeleccionarGuiaPendiente,
    idGuia,
    pendingGuide,
    nameGuia,
    limpiarNameGuia,
    vistaAnterior,
    actualizarVistaAnterior
  };
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};