import React from 'react'

const EntregaStatus = (props) => {

    const colorStatus = (status) => {
        switch (status) {
            case "A":
                return "#3BAF29";
            case "R":
                return "#ffb531";
            case "D":
                return "#35c1ed";
            case "T":
                return "#151b4e";
            case "P":
                return "#9597a7";
            case "C":
                return "#b40000";
        }
    }
    return (
        <svg width="50" height="50" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9337 0.419474C11.7227 0.576593 11.5645 7.38513 11.5645 15.5554C11.5645 28.544 11.6699 30.377 12.4084 30.6389C12.8831 30.796 19.1596 30.9531 26.3856 30.9531H39.5189V15.503V0.0528603H34.7719H30.0249V3.14289C30.0249 4.87121 29.8667 6.59953 29.7085 7.07089C29.2865 8.11835 27.9679 8.11835 26.4383 7.07089C25.3834 6.33766 25.1725 6.33766 24.1176 7.07089C23.4846 7.54225 22.5353 7.90886 22.0078 7.90886C21.1639 7.90886 21.0584 7.38513 21.0584 3.98086V0.0528603H16.6806C14.2544 0.0528603 12.0919 0.20998 11.9337 0.419474ZM27.8097 20.5832C28.1789 20.9498 28.4426 22.5734 28.4426 24.2493C28.4426 28.0726 27.9679 28.3345 21.3749 28.3345C15.3093 28.3345 14.7291 27.9678 14.7291 23.9351C14.7291 22.4686 14.9928 20.9498 15.362 20.5832C16.2059 19.7452 26.9658 19.7452 27.8097 20.5832Z" fill={colorStatus(props.estatus)} />
            <path d="M16.416 23.9843L16.5743 26.4983L21.7432 26.6554L26.8594 26.8125V24.1415V21.5228H21.5322H16.2578L16.416 23.9843Z" fill={colorStatus(props.estatus)} />
            <path d="M22.6406 3.19585V6.33825L24.012 5.50028C25.2778 4.66231 25.4361 4.66231 26.9129 5.50028L28.4425 6.39062V3.24823V0.053452H25.5416H22.6406V3.19585Z" fill={colorStatus(props.estatus)} />
            <path d="M41.1016 15.503V30.9531H44.1607C45.8485 30.9531 47.4836 30.6913 47.8528 30.3246C48.7495 29.4343 48.7495 1.57169 47.8528 0.681339C47.4836 0.314726 45.8485 0.0528603 44.1607 0.0528603H41.1016V15.503Z" fill={colorStatus(props.estatus)} />
            <path d="M49.8037 15.503C49.8037 29.4343 49.9092 30.9531 50.6476 30.9531C52.5992 30.9531 52.7046 29.9057 52.5992 14.9269L52.4409 0.314728L51.1223 0.157608L49.8037 0.000488281V15.503Z" fill={colorStatus(props.estatus)} />
            <path d="M0.118674 30.2192C-0.0395582 30.6382 -0.0395582 32.576 0.118674 34.4614C0.487884 38.4418 4.97114 48.8641 7.55561 51.797C8.45226 52.7921 10.9312 54.4681 13.0938 55.5155C17.3133 57.5057 18.8429 59.0769 19.4231 61.9051L19.7923 63.6858L24.9612 63.8429L30.1301 64L29.8137 61.4861C29.1807 57.2962 27.9149 52.8969 26.5435 50.3829C24.6975 46.8739 15.1508 36.9754 14.0431 37.3944C12.6191 37.9181 12.9883 39.6988 14.9925 42.3174C17.9462 46.1931 17.5243 46.9263 12.9883 45.8788C9.29617 45.0409 9.03245 44.8314 6.34249 40.7462C4.86565 38.3894 4.12723 36.6611 3.86351 34.6186C3.65254 33.0474 3.28333 31.2143 3.07235 30.5334C2.54491 29.1717 0.593372 28.9622 0.118674 30.2192Z" fill={colorStatus(props.estatus)} />
            <path d="M58.8234 29.7989C58.6652 30.1132 58.1378 32.1033 57.7158 34.3554C57.1356 37.4978 56.5027 39.0166 54.7094 41.5829C52.5469 44.6729 52.2304 44.8824 49.013 45.7204C46.3231 46.4536 45.4792 46.506 44.9517 45.9823C44.4243 45.5109 44.7407 44.7777 46.3758 42.6304C48.6438 39.7499 49.013 37.9168 47.5889 37.3931C46.2703 36.8693 36.7236 47.0821 34.7193 51.1672C33.2425 54.2049 31.6602 60.1755 31.6074 62.7941C31.6074 63.9463 31.7129 63.9987 36.7236 63.8416L41.8398 63.6845L42.2618 61.5895C42.8419 58.8137 44.3715 57.2949 48.4856 55.4619C53.4435 53.2622 54.8149 51.6386 58.296 44.2016C61.0387 38.1263 61.3024 37.2883 61.3552 33.6222C61.4079 29.9037 61.3024 29.6418 60.2475 29.4847C59.6673 29.3799 58.9817 29.5371 58.8234 29.7989Z" fill={colorStatus(props.estatus)} />
            <path d="M63.1481 31.6852C63.2535 32.9945 63.0953 35.299 62.7788 36.8178C62.0932 40.0126 57.9791 49.1779 56.0276 51.9013L54.709 53.7344L56.5023 51.9013C57.5044 50.8538 59.456 47.4496 61.091 43.8358C63.6755 38.1795 63.9392 37.2368 63.992 33.6754C64.0447 31.4757 63.8337 29.6426 63.5173 29.5379C63.2008 29.4331 63.0426 30.2711 63.1481 31.6852Z" fill={colorStatus(props.estatus)} />
            <path d="M45.1091 59.1846C43.9487 60.4416 42.9993 64.1077 43.8959 63.7935C44.2124 63.6887 44.6871 62.6936 44.8981 61.5938C45.1618 60.494 45.5838 59.2894 45.9002 58.8704C46.8496 57.8229 46.1112 58.0848 45.1091 59.1846Z" fill={colorStatus(props.estatus)} />
        </svg>
    )
}

export default EntregaStatus
