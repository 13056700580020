import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/authcontext';
import { useNavigate } from 'react-router-dom';
import useNuevaPeticion from '../Petiticiones/Nuevo/hooks/useNuevapeticion';
import useAsignarRecolector from '../Petiticiones/hooks/useAsignarRecolector';
import UseRecolectores from '../hooks/UseRecolectores';
import useModal from '../../utils/Components/modal/usemodal';
import { PeticionesLayout } from '../../theme/Layout';
import moment from 'moment';
import Swal from 'sweetalert2';
import ModalComponent from '../../utils/Components/modal/ModalComponent';
import imgAsignar from '../../assets/iconos/addRecolector.svg';


const DetallesPetiPendientes = (props) => {

  const navigate = useNavigate();
  const {
    user,
    infoGuia,
    SeleccionarGuiaPendiente,
    idGuia } = useAuth();
  const { HanlderCambioTelefonoPeticion } = useNuevaPeticion();
  const { ModalCambioRecolector, AbrirModalCambioRecolector, CerrarModalCambioRecolector } = useModal();
    const { HandlerAsignarRecolector } = useAsignarRecolector();
    const { LoadRecolectores, Recolectores } = UseRecolectores();
    const [PeticionSeleccionada, setPeticionSeleccionada] = useState();
  // const informacionClienteGuia = () => {
  //   HanlderCambioTelefonoPeticion(res.telefono)
  // }

  const NavigateNuevaPeticion = (id) => {
    SeleccionarGuiaPendiente(id)
    navigate('/Peticiones/nuevo')
  }
  console.log(infoGuia, "infoGuia")
  const HandleModalAsignarRecolector = (e, PeticionSeleccionada) => {
    setPeticionSeleccionada(PeticionSeleccionada)
    AbrirModalCambioRecolector(e)
}
const HandlerCerrarModalRecolector = () => {
    CerrarModalCambioRecolector()
    setPeticionSeleccionada()
}
   useEffect(() => {
        LoadRecolectores(JSON.parse(localStorage.getItem("Ciudad")).idciudad)
    }, [user])
 const AsignarRecolector = (peticion, recolector, nombre) => {
        Swal.fire({
            title: `Esta por reasignar la petición del cliente ${PeticionSeleccionada.cliente} al recolector ${nombre}`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            denyButtonText: `Cancelar`,
            denyButtonColor: "#DC1D1D",
            confirmButtonColor: "#3BAF29"
        }).then((result) => {
            if (result.isConfirmed) {
                HandlerAsignarRecolector(recolector, peticion)
                HandlerCerrarModalRecolector()
            }
        })
    }
const RenderItemCambioRecolector = () => {
  return (
      <PeticionesLayout>
          <div className='Cuerpo-ListaModal'>
              {
                  Recolectores?.map(resp => {
                      return (
                          <>
                              <div onClick={(result) => AsignarRecolector(PeticionSeleccionada.id, resp.id, resp.descripcion)} className="ItemCuerpoLista">
                                  <div style={{ width: '45px', paddingRight: '10px' }}>
                                      {/* <img style={{width:'100%'}} src={imgRecolector} alt="" /> */}
                                  </div>
                                  <div>
                                      <p>{resp.descripcion}</p>
                                  </div>
                              </div></>
                      )
                  })
              }
          </div>
      </PeticionesLayout>
  )
}
  return (
    <>
    <div style={{
      height: '500px',
      // background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      // boxShadow: 'rgb(106, 106, 106) -1px 2px 14px -3px'
    }}
    className='containerPendingPetitions'>
      <div className='containerWidget'>
      <p style={{ paddingTop: '2px', textAlign: 'center', fontWeight: 'bold', color:'#151B4E',fontSize: '18px' }}>Peticiones pendientes</p>
      </div>
      {
        props.PeticionesMapa?.map(res => {
          console.log(props.PeticionesMapa, "PeticionesMapa")
          if (res.idusuarioasignado === 0 && res.estatus === "P" && res.estatus != "C") {
            return (
              <>
                <div style={{
                  borderBottom: 'solid #CCCCCC 1px',
                  width: '90%',
                  cursor: 'pointer'
                }}
                  >
                  <p onClick={() => NavigateNuevaPeticion(res.id)} style={{ fontWeight: 'bold'}}>{res.id} - {res.cliente}</p>
                  <div style={{ display: 'flex', flexDirection: 'row', width:'100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width:'90%' }}>
                      <img src={res.urlorigen} style={{ width: '25px', height: '18px' }} />
                      <p style={{ marginTop: '0px', paddingLeft: '5px' }}>{moment(res.fecha).format('DD/MM/YYYY hh:mm A')}</p>
                    </div>
                    <div style={{ width: '10%', height: '18px' }}>
                      <img style={{ width: '25px', height: '100%' }}
                       onClick={(e) => HandleModalAsignarRecolector(e, res)} src={imgAsignar} />
                    </div>
                  </div>
                </div>
              </>
            )
          }
        })
      }
    </div>
 
     <ModalComponent
         Nombremodal={ModalCambioRecolector}
         Cerrarmodal={HandlerCerrarModalRecolector}
         Titulo={"Seleccionar recolector"}
         Contenido={RenderItemCambioRecolector}
     ></ModalComponent>
    
 </>
  )
}

export default DetallesPetiPendientes
