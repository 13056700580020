import React from 'react'

const RecoleccionStatus = (props) => {

  const colorStatus = (status) => {
    switch (status) {
      case "A":
        return "#3BAF29";
      case "R":
        return "#ffb531";
      case "D":
        return "#35c1ed";
      case "T":
        return "#151b4e";
      case "P":
        return "#9597a7";
      case "C":
        return "#b40000";
    }
  }
  return (
    <svg width="50" height="50" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8394 0.0956001C9.97059 0.324642 8.72941 1.09766 8.12261 1.77047C7.81921 2.11403 7.36411 2.82979 7.12967 3.35945C6.75731 4.18973 6.70215 4.47603 6.70215 5.46378C6.70215 6.8094 6.96418 7.52516 8.20535 9.4577C8.96385 10.6315 11.198 13.4516 11.8461 14.0385C12.1358 14.3105 12.1358 14.3105 12.4943 13.967C13.5976 12.9077 15.7628 10.0303 16.6592 8.46996C17.8314 6.37995 17.9279 5.04864 17.0315 3.15904C16.1351 1.26944 14.4526 0.109915 12.4392 0.0097084C11.8599 -0.0189219 11.1428 0.024024 10.8394 0.0956001ZM13.1563 2.41465C14.3423 2.78685 15.4456 4.36151 15.4456 5.67851C15.4456 6.9955 14.3423 8.57016 13.1563 8.94236C11.8599 9.34318 10.8256 9.05688 9.86026 8.0405C8.45359 6.59467 8.45359 4.76234 9.86026 3.31651C10.8256 2.30013 11.8599 2.02814 13.1563 2.41465Z" fill={colorStatus(props.estatus)} />
      <path d="M11.3773 4.88777C10.6877 5.58921 11.1704 6.82031 12.1358 6.82031C12.7012 6.82031 13.239 6.26202 13.239 5.6751C13.239 5.08818 12.7012 4.52989 12.1358 4.52989C11.8737 4.52989 11.5841 4.65873 11.3773 4.88777Z" fill={colorStatus(props.estatus)} />
      <path d="M30.8921 11.6069C30.2163 11.836 29.0579 13.0671 28.851 13.7971C28.7407 14.2123 28.6855 15.7726 28.6855 18.8504V23.3167L28.3408 23.6603L28.0098 24.0182H20.9626H13.9155L13.5845 23.6603C13.2535 23.331 13.2397 23.2165 13.2397 21.7277C13.2397 20.239 13.226 20.1244 12.895 19.7952C12.4537 19.3228 11.8193 19.3228 11.378 19.7952C11.047 20.1244 11.0332 20.239 11.0332 21.742C11.0332 22.6153 11.1022 23.603 11.1987 23.9323C11.4193 24.7196 12.564 25.9077 13.3225 26.1368C13.7362 26.2656 15.8186 26.3086 20.9902 26.3086C29.1268 26.3086 28.9613 26.3372 29.9681 25.1634C30.2991 24.7912 30.6438 24.2186 30.7266 23.9036C30.8369 23.5171 30.8921 21.9138 30.8921 18.879V14.4127L31.2368 14.0691L31.5678 13.7112H39.7182H47.8686L48.1996 14.0691L48.5444 14.4127V22.3146C48.5444 28.098 48.5858 30.3741 48.7099 30.8035C48.9305 31.5909 50.0752 32.779 50.8337 33.0081C51.2336 33.1226 52.8471 33.1798 56.2535 33.1798H61.1079L61.4389 33.5377L61.7836 33.8813V41.7689V49.6566L61.4389 50.0001C61.1079 50.358 61.0803 50.358 58.4738 50.358C55.8673 50.358 55.8398 50.358 55.5088 50.7159C55.2881 50.9306 55.164 51.2312 55.164 51.5032C55.164 51.7752 55.2881 52.0758 55.5088 52.2906C55.8398 52.6484 55.8673 52.6484 58.4876 52.6484C60.1563 52.6484 61.3423 52.5912 61.7009 52.4767C62.4594 52.2476 63.604 51.0595 63.8247 50.2721C64.0591 49.4275 64.0591 34.1103 63.8247 33.2657C63.604 32.4784 62.4594 31.2902 61.7009 31.0612C61.3009 30.9467 59.6874 30.8894 56.2811 30.8894H51.4267L51.0957 30.5315L50.7509 30.188V22.286C50.7509 16.6029 50.7095 14.2266 50.5854 13.8258C50.5027 13.5108 50.1579 12.9382 49.8269 12.566C48.8064 11.3779 49.1374 11.4208 39.6631 11.4351C34.0364 11.4495 31.223 11.5067 30.8921 11.6069Z" fill={colorStatus(props.estatus)} />
      <path d="M0 39.9867V49.1484H15.4458H30.8916V39.9867V30.8251H15.4458H0V39.9867Z" fill={colorStatus(props.estatus)}/>
      <path d="M33.0977 46.9086V56.0703H34.6836C35.6076 56.0703 36.2696 56.0131 36.2696 55.9272C36.2696 55.8556 36.6419 55.3832 37.097 54.8822C39.7724 51.9046 44.0752 51.9046 46.7506 54.8822C47.2057 55.3832 47.5781 55.8556 47.5781 55.9272C47.5781 56.0131 48.24 56.0703 49.164 56.0703H50.75V52.42V48.7553L50.4052 48.4117L50.0742 48.0538H44.1166C39.8414 48.0538 38.0072 48.0109 37.5935 47.8821C36.835 47.653 35.6903 46.4649 35.4697 45.6775C35.3594 45.2767 35.3042 43.8738 35.3042 41.4116V37.7469H34.2009H33.0977V46.9086ZM37.166 49.5569C37.3866 49.7717 37.5107 50.0723 37.5107 50.3443C37.5107 50.6162 37.3866 50.9169 37.166 51.1316C36.8764 51.4465 36.7109 51.4895 35.8558 51.4895C35.0008 51.4895 34.8353 51.4465 34.5457 51.1316C34.325 50.9169 34.2009 50.6162 34.2009 50.3443C34.2009 50.0723 34.325 49.7717 34.5457 49.5569C34.8353 49.242 35.0008 49.199 35.8558 49.199C36.7109 49.199 36.8764 49.242 37.166 49.5569Z" fill={colorStatus(props.estatus)}/>
      <path d="M37.5107 41.3683V45.0329L37.8555 45.3765L38.1865 45.7344H42.6823H47.1644L47.0954 45.4051C47.054 45.2333 46.7782 43.9307 46.4748 42.5135C45.8404 39.5502 45.537 38.8917 44.4889 38.2189L43.8132 37.7895L40.6551 37.7465L37.5107 37.7036V41.3683Z" fill={colorStatus(props.estatus)}/>
      <path d="M0 53.7799V56.0703H3.79249C6.13694 56.0703 7.58498 56.0131 7.58498 55.9272C7.58498 55.8556 7.95733 55.3832 8.41243 54.8822C11.0879 51.9046 15.3906 51.9046 18.066 54.8822C18.5211 55.3832 18.8935 55.8556 18.8935 55.9272C18.8935 56.0131 21.1414 56.0703 24.8925 56.0703H30.8916V53.7799V51.4895H15.4458H0V53.7799Z" fill={colorStatus(props.estatus)}/>
      <path d="M12.2054 55.0352C10.9366 55.3787 9.81956 56.3235 9.24034 57.5546C8.77145 58.5424 8.75766 60.389 9.21276 61.3482C9.68165 62.3645 10.2471 62.9801 11.1986 63.5097C11.9985 63.9535 12.164 63.9965 13.2397 63.9821C14.2326 63.9821 14.5222 63.9249 15.1153 63.6099C16.1496 63.0373 16.5909 62.6079 17.1149 61.6058C17.5424 60.7755 17.5838 60.6038 17.5838 59.4872C17.5838 58.3706 17.5424 58.1988 17.1149 57.3685C16.3289 55.8798 15.129 55.0781 13.5293 54.9779C13.0328 54.9493 12.4398 54.9636 12.2054 55.0352Z" fill={colorStatus(props.estatus)}/>
      <path d="M40.89 55.0352C39.6212 55.3787 38.5041 56.3235 37.9249 57.5546C37.456 58.5424 37.4422 60.389 37.8973 61.3482C38.3662 62.3645 38.9316 62.9801 39.8832 63.5097C40.6831 63.9535 40.8486 63.9965 41.9243 63.9821C42.9172 63.9821 43.2068 63.9249 43.7998 63.6099C44.8341 63.0373 45.2754 62.6079 45.7995 61.6058C46.227 60.7755 46.2684 60.6038 46.2684 59.4872C46.2684 58.3706 46.227 58.1988 45.7995 57.3685C45.0134 55.8798 43.8136 55.0781 42.2139 54.9779C41.7174 54.9493 41.1244 54.9636 40.89 55.0352Z" fill={colorStatus(props.estatus)}/>
      <path d="M2.20605 59.1453C2.20605 59.8038 2.26122 60.0043 2.55083 60.2906C2.86802 60.6341 2.95076 60.6484 4.78495 60.6484H6.68809V59.5032V58.358H4.45397H2.20605V59.1453Z" fill={colorStatus(props.estatus)} />
      <path d="M19.79 59.5032V60.6484H27.5819H35.3737V59.5032V58.358H27.5819H19.79V59.5032Z" fill={colorStatus(props.estatus)} />
      <path d="M48.4746 59.5032V60.6484H50.3778C52.2119 60.6484 52.2947 60.6341 52.6119 60.2906C52.9015 60.0043 52.9566 59.8038 52.9566 59.1453V58.358H50.7225H48.4746V59.5032Z" fill={colorStatus(props.estatus)} />
    </svg>
  )
}

export default RecoleccionStatus
