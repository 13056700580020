import React, { useState } from 'react'
import axios from 'axios';
import config from '../../../../config/config';
import { useNavigate } from 'react-router-dom';
import useErrorAlert from '../../../../utils/hooks/useErrorAlert';
import { useAuth } from '../../../../context/authcontext';

const useNuevaPeticion = () => {
    const { limpiarNameGuia } =useAuth();
    const navigate = useNavigate();
    const [TipoModalDetalle, setTipoModalDetalle] = useState();
    const { HanlderMensajeCorrecto } = useErrorAlert()
    const HanlderTipoDetalle = (data) => {
        setTipoModalDetalle(data)
    }
    // ORIGEN
    const [OrigenSelecionado, setOrigenSelecionado] = useState();
    const [Recolector, setRecolector] = useState(null)
    // VARIABLES CLIENTE
    const [ClienteBuscador, setClienteBuscador] = useState('')
    const [ClienteSeleccionado, setClienteSeleccionado] = useState();
    const [Codigopostal, setCodigopostal] = useState('');
    const [TelefonoPeticion, setTelefonoPeticion] = useState()
    const [CallePeticion, setCallePeticion] = useState()
    const [NumeroPeticion, setNumeroPeticion] = useState()
    const [NumeroIntPeticion, setNumeroIntPeticion] = useState()
    const [ColoniaPeticion, setColoniaPeticion] = useState()
    const [CiudadPeticion, setCiudadPeticion] = useState()
    const [EstadoPeiticion, setEstadoPeiticion] = useState()
    const [PaisPeticion, setPaisPeticion] = useState()
    const [MunicipioPeticion, setMunicipioPeticion] = useState()
    const [CorreoPeticion, setCorreoPeticion] = useState()
    const [ReferenciaPeticion, setReferenciaPeticion] = useState()
    const [LatitudePeticion, setLatitudePeticion] = useState()
    const [LongitudePeticion, setLongitudePeticion] = useState()

    // DATOS ONSITE
    const [SelectMercanciaOnsite, setSelectMercanciaOnsite] = useState([]);
    // VARIABLES DE NUEVA PETICION
    const [PaisNueva, setPaisNueva] = useState('')
    const [CalleNueva, setCalleNueva] = useState('')
    const [NumeroExtNueva, setNumeroExtNueva] = useState('')
    const [NumIntNueva, setNumIntNueva] = useState('')
    const [CpNueva, setCpNueva] = useState('')
    const [ColoniaNueva, setColoniaNueva] = useState('')
    const [CiudadNueva, setCiudadNueva] = useState('')
    const [MunicipioNueva, setMunicipioNueva] = useState('')
    const [EstadoNueva, setEstadoNueva] = useState('')
    const [CorreoNueva, setCorreoNueva] = useState('')
    const [TelefonoNueva, setTelefonoNueva] = useState('');
    const [LatitudeNueva, setLatitudeNueva] = useState('');
    const [LongitudeNueva, setLongitudeNueva] = useState('');
    // DATOS DETALLES 
    const [Cantidad, setCantidad] = useState('');
    const [Contenido, setContenido] = useState('');
    const [Largo, setLargo] = useState('')
    const [Ancho, setAncho] = useState('')
    const [Alto, setAlto] = useState('')
    const [Peso, setPeso] = useState('')
    const [TipoDetalles, setTipoDetalles] = useState('')
    //NUEVO CLIENTE
    const [NewClteInfo, setNewClteInfo] = useState()
    const [idNuevoCliente, setidNuevoCliente] = useState();
    const [nombreCliente, setnombreCliente] = useState('');
    const [VistaNuevoCliente, setVistaNuevoCliente] = useState(false)

    const handlerNombreCliente = (data) => {
        setnombreCliente(data)
    }
    const AbrirVistaNuevoCliente = () => {
        setVistaNuevoCliente(true)
    }
    const CerrarVistaNuevoCliente = () => {
        setVistaNuevoCliente(false)
        LimpiarNueva()
        // LimpiarNueva()
    }
    const handleNewClteInfo = (data) => {
        setNewClteInfo(data)
    }

    // FUNCIONES DETALLES
    const HanlderTipodetalle = (data) => {
        setTipoDetalles(data)
    }

    const HandlerCantidad = (data) => {
        setCantidad(data)
    }
    const HanlderContenido = (data) => {
        setContenido(data)
    }
    const HanlderLargo = (data) => {
        setLargo(data)
    }
    const HandlerAncho = (data) => {
        setAncho(data)
    }
    const HanlderAlto = (data) => {
        setAlto(data)
    }
    const HanlderPeso = (data) => {
        setPeso(data)
    }

    // FUNCIONES NUEVA PETICION
    const LimpiarNuevapeticion = () => {
        setCodigopostal()
        setTelefonoPeticion()
        setCallePeticion()
        setNumeroPeticion()
        setColoniaPeticion()
        setCiudadPeticion()
        setEstadoPeiticion()
        setPaisPeticion()
        setReferenciaPeticion()
    }
    const HandlerCambioCodigoposta = (data) => {
        setCodigopostal(data)
    }
    const HanlderCambioTelefonoPeticion = (data) => {
        setTelefonoPeticion(data)
    }
    const HanlderCambioCorreoPeticion = (data) => {
        setCorreoPeticion(data)
    }
    const HanlderCambioCallePeticion = (data) => {
        setCallePeticion(data)
    }
    const HanlderCambioNumeroPeticion = (data) => {
        setNumeroPeticion(data)
    }
    const HanlderCambioNumeroIntPeticion = (data) => {
        setNumeroIntPeticion(data)
    }
    const HanlderCambioColoniaPeticion = (data) => {
        setColoniaPeticion(data)
    }
    const HandlerCambioCiudadPeticion = (data) => {
        setCiudadPeticion(data)
    }
    const HandlerCambioMunicipioPeticion = (data) => {
        setMunicipioPeticion(data)
    }
    const HanlderCambioEstadoPeticion = (data) => {
        setEstadoPeiticion(data)
    }
    const HandlerCambioPaisPeticion = (data) => {
        setPaisPeticion(data)
    }
    const HanlderCambioReferenciaPeticion = (data) => {
        setReferenciaPeticion(data)
    }
    // FUNCIONES NUEVA PETICION
    const HanlderCambioLatitudeNueva = (data) => {
        setLatitudeNueva(data)
    }
    const HanlderCambioLongitudeNueva = (data) => {
        setLongitudeNueva(data)
    }

    const HandlerCambioPaisNueva = (data) => {
        setPaisNueva(data)
    }
    const HandlerCambioCalleNueva = (data) => {
        setCalleNueva(data)
    }
    const HandlerCambioNumeroExtNueva = (data) => {
        setNumeroExtNueva(data)
    }
    const HandlerCambioNumIntNueva = (data) => {
        setNumIntNueva(data)
    }
    const HandlerCambioCpNueva = (data) => {
        setCpNueva(data)
    }
    const HandlerCambioColoniaNueva = (data) => {
        setColoniaNueva(data)
    }
    const HandlerCambioCiudadNueva = (data) => {
        setCiudadNueva(data)
    }
    const HandlerCambioMunicipioNueva = (data) => {
        setMunicipioNueva(data)
    }
    const HandlerCambioEstadoNueva = (data) => {
        setEstadoNueva(data)
    }
    const HandlerCambioCorreoNueva = (data) => {
        setCorreoNueva(data)
    }
    const HandlerCambioTelefonoNueva = (data) => {
        setTelefonoNueva(data)
    }

    const LimpiarNueva = () => {
        // alert("holis")
        setnombreCliente('')
        setPaisNueva('')
        setCalleNueva('')
        setNumeroExtNueva('')
        setNumIntNueva('')
        setCpNueva('')
        setColoniaNueva('')
        setCiudadNueva('')
        setMunicipioNueva('')
        setEstadoNueva('')
        setCorreoNueva('')
        setTelefonoNueva('')
    }


    // FUNCION DE CAMBIO ORIGEN
    const HanlderCambioOrigen = (e, origen, cliente) => {
        e.preventDefault()
        if (cliente) {
        } else if (origen === "SUCURSAL") {
            setOrigenSelecionado("Sucursal")
        } else if (origen === "ONSITE") {
            setOrigenSelecionado("OnSite")
        }
    }
    // FUNCION PARA PETICION 

    const HanlderClienteBuscador = (data) => {
        setClienteBuscador(data)
        
    }
    const limpiarClienteBuscador = () => {
        setClienteBuscador('')
        limpiarNameGuia()
        setClienteSeleccionado()
    }
    const HanlderSeleccionarCliente = (data) => {
        console.log('crup useNewP: ', data)
        setClienteSeleccionado(data)
        // alert("HanlderSeleccionarCliente")
    }

    // FUCNCION RECOLECTOR
    const HanlderSelecionarRecolector = (id) => {
        // if(Recolector.includes(id)){
        //     let copiaRecolector = Recolector.filter(function (copia){
        //         return copia !== id
        //     })
        //     setRecolector(copiaRecolector)
        // } else{
        //     let arraycopi = [...Recolector]
        //     arraycopi.push(id)
        //     setRecolector(arraycopi)
        // }
        setRecolector([id])
    }

    //FUNCIONES ONSITE
    const HandlerSelectMercanciaOS = (id) => {
        if (SelectMercanciaOnsite.includes(id)) {
            let copia = SelectMercanciaOnsite.filter(function (copia) {
                return copia !== id
            })
            setSelectMercanciaOnsite(copia)
        } else {
            let arraycopi = [...SelectMercanciaOnsite]
            arraycopi.push(id)
            setSelectMercanciaOnsite(arraycopi)
        }
    }
    const HandlerSelectMercanciaOSAll = (data) => {
        if (SelectMercanciaOnsite?.length === 0) {
            let AllDocs = data.map(res => {
                return res.id
            })
            setSelectMercanciaOnsite(AllDocs)

        } else {
            let AllDocs = data.map(res => {
                return res.id
            })
            setSelectMercanciaOnsite(AllDocs)
        }
    }
    const HandlerDeseleccionar = () => {
        let Copia = [...SelectMercanciaOnsite]
        Copia.splice(0);
        setSelectMercanciaOnsite(Copia)
    }

    //FUNCIONES NUEVO CLIENTE
    const AñadirNuevoCliente = async (request, Responsableid) => {
        // setLoadingNuevaDireccion(true)
        let isMounted = true;
        const source = axios.CancelToken.source();
        const URL_NUEVOCLIENTE = `${config.API_URL}Cliente/Add/${Responsableid}`
        try {

            const ResponseNuevoCliente = await axios.post(URL_NUEVOCLIENTE, request, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer  ${JSON.parse(localStorage.getItem("Token"))}`
                },
            })
            if (isMounted) {
                // setLoadingNuevaDireccion(false)
                let idNvo = ResponseNuevoCliente.data.idcliente;
                setidNuevoCliente(idNvo)
                HanlderMensajeCorrecto('Se agregó el cliente correctamente')
                CerrarVistaNuevoCliente()
                //   LimpiarNueva()
                //   navigate('/Peticiones/nuevo')
                // console.log(request, "requestttttttttttt")
            }
        } catch (error) {
            console.error(error)
        }
    }

    return {
        OrigenSelecionado,
        HanlderCambioOrigen,
        ClienteBuscador,
        HanlderClienteBuscador,
        limpiarClienteBuscador,
        ClienteSeleccionado,
        HanlderSeleccionarCliente,
        SelectMercanciaOnsite,
        HandlerSelectMercanciaOS,
        HandlerSelectMercanciaOSAll,
        HandlerDeseleccionar,
        // NUEVA DIRECCION
        PaisNueva,
        CalleNueva,
        NumeroExtNueva,
        NumIntNueva,
        CpNueva,
        ColoniaNueva,
        CiudadNueva,
        MunicipioNueva,
        EstadoNueva,
        CorreoNueva,
        TelefonoNueva,
        HandlerCambioPaisNueva,
        HandlerCambioCalleNueva,
        HandlerCambioNumeroExtNueva,
        HandlerCambioNumIntNueva,
        HandlerCambioCpNueva,
        HandlerCambioColoniaNueva,
        HandlerCambioCiudadNueva,
        HandlerCambioMunicipioNueva,
        HandlerCambioEstadoNueva,
        HandlerCambioCorreoNueva,
        HandlerCambioTelefonoNueva,
        LimpiarNueva,
        LatitudeNueva,
        LongitudeNueva,
        HanlderCambioLatitudeNueva,
        HanlderCambioLongitudeNueva,
        Codigopostal,
        TelefonoPeticion,
        CallePeticion,
        NumeroPeticion,
        ColoniaPeticion,
        CiudadPeticion,
        MunicipioPeticion,
        EstadoPeiticion,
        PaisPeticion,
        ReferenciaPeticion,
        CorreoPeticion,
        LimpiarNuevapeticion,
        HandlerCambioCodigoposta,
        HanlderCambioTelefonoPeticion,
        HanlderCambioCallePeticion,
        HanlderCambioNumeroPeticion,
        HanlderCambioColoniaPeticion,
        HandlerCambioCiudadPeticion,
        HanlderCambioEstadoPeticion,
        HandlerCambioMunicipioPeticion,
        HandlerCambioPaisPeticion,
        HanlderCambioCorreoPeticion,
        HanlderCambioReferenciaPeticion,
        // RECOLECTOR
        HanlderSelecionarRecolector,
        Recolector,
        // DETALLES
        Cantidad,
        Contenido,
        Largo,
        Ancho,
        Alto,
        Peso,
        HandlerCantidad,
        HanlderContenido,
        HanlderLargo,
        HandlerAncho,
        HanlderAlto,
        HanlderPeso,
        HanlderTipodetalle,
        TipoDetalles,

        // TIPO DETALLE
        TipoModalDetalle,
        HanlderTipoDetalle,

        //NUEVO CLIENTE
        nombreCliente,
        handlerNombreCliente,
        handleNewClteInfo,
        VistaNuevoCliente,
        AbrirVistaNuevoCliente,
        CerrarVistaNuevoCliente,

        //FUNCION AGREGAR CLIENTE
        AñadirNuevoCliente
    }
}

export default useNuevaPeticion