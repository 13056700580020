import React from 'react'

const BancoStatus = (props) => {

  const colorStatus = (status) => {
    switch (status) {
      case "A":
        return "#3BAF29";
      case "R":
        return "#ffb531";
      case "D":
        return "#35c1ed";
      case "T":
        return "#151b4e";
      case "P":
        return "#9597a7";
      case "C":
        return "#b40000";
    }
  }
  return (
    <svg width="50" height="50" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.3772 0.0490379C31.1395 0.102669 30.7267 0.263565 30.4766 0.411053C30.0638 0.638988 2.39421 27.3611 0.968205 28.9164C-0.0950463 30.0695 -0.295188 31.6114 0.430325 33.046C1.01824 34.1991 1.96891 34.7891 3.25732 34.7891C4.2205 34.7891 4.82093 34.5075 5.67153 33.6896C6.02178 33.3678 8.66114 30.8739 11.5507 28.1387C14.4402 25.4169 20.1818 19.9867 24.2972 16.0984C28.4126 12.1966 31.865 9.00555 31.9401 8.99215C32.0276 8.99215 38.0944 14.6503 45.4371 21.5822C52.7673 28.5141 58.9842 34.3064 59.2344 34.4539C59.8098 34.7757 61.1858 34.8829 61.8112 34.6684C63.4248 34.0918 64.388 32.0136 63.8501 30.284C63.7376 29.922 63.4373 29.3722 63.1747 29.0639C62.9245 28.7689 60.6854 26.5566 58.2087 24.17C55.7319 21.7833 49.2273 15.4816 43.7609 10.1855C37.494 4.11165 33.6288 0.464684 33.3161 0.317196C32.6906 0.0356293 31.9401 -0.0716362 31.3772 0.0490379Z" fill={colorStatus(props.estatus)} />
      <path d="M47.0256 8.21451C46.5378 8.46926 46.1875 8.93854 46.0749 9.46145C45.9999 9.78324 46.4002 10.2123 51.191 14.8649C54.0556 17.6403 56.4197 19.9062 56.4573 19.9062C56.4948 19.9062 56.5198 17.533 56.5198 14.6235C56.5198 7.42344 56.9576 7.97317 51.2661 7.97317C47.8762 7.97317 47.4009 7.99998 47.0256 8.21451Z" fill={colorStatus(props.estatus)} />
      <path d="M19.644 22.9768L7.36035 34.5881L7.38537 47.862C7.4229 62.7314 7.34784 61.5515 8.34855 62.718C8.63625 63.0532 9.16162 63.4554 9.52438 63.6297L10.1748 63.9515L31.3773 63.9917C44.3865 64.0185 52.88 63.9783 53.3553 63.8979C54.7063 63.6699 55.5819 63.0398 56.1698 61.833L56.4575 61.2699L56.4951 47.9156L56.5201 34.5613L51.429 29.7478C44.6742 23.3656 32.5031 11.8884 32.1904 11.5934C31.9402 11.3655 31.64 11.6336 19.644 22.9768ZM34.8548 22.9231C38.8076 23.607 42.7228 25.9802 45.2746 29.2383C47.2635 31.7724 48.4644 34.4138 49.1649 37.7524C49.5652 39.7099 49.5652 43.1826 49.1524 45.2474C48.4144 48.9614 46.8883 51.9648 44.324 54.7134C41.8097 57.4352 39.0953 59.0308 35.4927 59.8889C33.7915 60.2911 30.4266 60.3179 28.7129 59.9559C22.6211 58.642 17.5926 54.0698 15.5036 47.9558C14.1276 43.9602 14.0901 39.3077 15.391 35.3523C16.9171 30.6461 20.1819 26.731 24.3724 24.5321C25.7233 23.8349 27.4746 23.2181 28.9381 22.9366C30.2891 22.6818 33.3912 22.6684 34.8548 22.9231Z" fill={colorStatus(props.estatus)} />
      <path d="M29.1879 24.8037C23.609 25.8897 18.9932 30.0864 17.0668 35.8384C16.2412 38.3189 15.9661 41.7513 16.4039 44.3793C17.7423 52.4241 24.322 58.3906 31.9023 58.3906C34.8044 58.3906 37.6063 57.5861 39.958 56.071C45.0991 52.7459 48.0262 46.9 47.7135 40.6251C47.5008 36.3077 45.9622 32.5803 43.1353 29.5635C40.7586 27.016 37.9066 25.407 34.6668 24.8037C33.3658 24.5623 30.4263 24.5623 29.1879 24.8037ZM34.3916 27.7803C34.9044 28.2495 35.1296 28.8395 35.1421 29.7914V30.5691L35.8801 30.9445C36.7808 31.4004 37.9066 32.473 38.4569 33.4116C39.0699 34.4172 39.4952 35.9189 39.5827 37.2999C39.6453 38.3457 39.6203 38.5334 39.3951 38.9223C38.9823 39.6195 38.4444 39.8876 37.4938 39.8876H36.6557L37.6314 40.9201C38.7446 42.1268 39.1825 43.0117 39.4827 44.6207C40.0831 47.919 38.4569 51.204 35.6425 52.3705L34.9044 52.6788L34.8419 53.4565C34.7043 55.1191 34.1164 55.575 32.1275 55.575C30.1261 55.575 29.5507 55.1191 29.4131 53.4297C29.363 52.9202 29.288 52.6252 29.1879 52.6252C29.1003 52.6252 28.5875 52.3839 28.0371 52.0889C26.1482 51.0833 24.8223 49.1123 24.4345 46.7659C24.1969 45.3447 24.2094 44.8888 24.4971 44.2452C24.8723 43.4139 25.2601 43.2396 26.6861 43.2396H27.8995L27.0364 42.3815C25.5853 40.9335 24.9974 39.4586 24.9974 37.1926C24.9974 35.0608 25.5228 33.6529 26.8612 32.2451C27.4742 31.6015 27.937 31.2529 28.6375 30.9043L29.5882 30.4484L29.6632 29.5233C29.7508 28.4909 29.9509 28.0618 30.5388 27.6864C30.8766 27.4585 31.1768 27.4182 32.4652 27.4182C33.9287 27.4182 34.0038 27.4316 34.3916 27.7803Z" fill={colorStatus(props.estatus)} />
      <path d="M31.3768 30.6336V31.9744L31.0391 32.0549C29.4755 32.4303 28.9626 32.6985 28.112 33.5431C27.1614 34.4951 26.8236 35.3666 26.7736 37.0024C26.7111 38.7186 26.9862 39.6035 27.9119 40.6628C28.6874 41.5343 29.513 42.0706 31.3768 42.9153C33.8411 44.0282 34.2789 44.4438 34.354 45.8517C34.429 47.3131 33.6034 48.198 32.1649 48.198C30.6764 48.198 29.7882 47.3533 29.5631 45.7444L29.4755 45.1142H27.7368H25.998L26.0731 45.7444C26.2732 47.4874 26.7486 48.5869 27.7368 49.5925C28.4748 50.3165 29.3629 50.7858 30.4762 51.0137L31.2518 51.188V52.435V53.6953H32.1899H33.1281V52.435V51.188L33.8661 51.0271C35.7049 50.6383 37.1684 49.3243 37.6438 47.6349C38.0065 46.3478 37.9315 44.8327 37.4561 43.7332C36.8057 42.2851 35.6674 41.3466 33.0655 40.1533C31.0141 39.2147 30.4887 38.7857 30.2385 37.8069C29.9508 36.694 30.4887 35.4471 31.4144 35.0717C32.9029 34.4683 34.379 35.7018 34.379 37.5655V38.008H36.1302H37.8814V37.6192C37.8814 35.0314 36.0552 32.5376 33.8911 32.1621L33.3783 32.0683V30.6873V29.2928H32.3776H31.3768V30.6336Z" fill={colorStatus(props.estatus)} />
      <path d="M32.0029 37.122C32.0029 37.3097 32.128 37.551 32.2906 37.6717C32.5533 37.8728 32.5658 37.8594 32.6034 37.5376C32.6534 37.1488 32.4407 36.8002 32.1781 36.8002C32.078 36.8002 32.0029 36.9343 32.0029 37.122Z" fill={colorStatus(props.estatus)} />
      <path d="M31.3151 45.4756C31.4277 46.1728 31.5778 46.3203 32.1532 46.3203C32.616 46.3203 32.641 46.2935 32.6035 45.9583C32.5785 45.6901 32.4284 45.5024 32.0656 45.2879C31.3401 44.8454 31.2025 44.8723 31.3151 45.4756Z" fill={colorStatus(props.estatus)} />
    </svg>
  )
}

export default BancoStatus
