import React from 'react'

const OtroStatus = (props) => {

  const colorStatus = (status) => {
    switch (status) {
      case "A":
        return "#3BAF29";
      case "R":
        return "#ffb531";
      case "D":
        return "#35c1ed";
      case "T":
        return "#151b4e";
      case "P":
        return "#9597a7";
      case "C":
        return "#b40000";
    }
  }
  return (
    <svg width="50" height="50" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.3446 0.0384712C27.7979 0.195938 25.5924 0.582451 24.0887 0.968964C13.2619 3.64592 4.32552 12.2637 1.23215 23.0002C0.258311 26.3643 -0.0137904 28.3398 0.000530724 32.0904C0.000530724 34.6528 0.0578153 35.569 0.315596 37.0292C1.54722 44.1868 4.68355 50.1849 9.73891 55.0665C13.6343 58.8314 18.26 61.4797 23.3727 62.8826C26.5663 63.7558 28.3564 63.9849 31.9224 63.9992C35.3881 64.0135 36.8345 63.8417 39.9279 63.0687C48.8213 60.8498 56.5548 54.594 60.7365 46.2625C62.0254 43.7001 63.1998 39.9638 63.7297 36.6713C64.0877 34.4954 64.0877 29.7284 63.744 27.4379C61.5672 13.2801 50.769 2.47207 36.6484 0.296146C35.2592 0.0814171 30.5189 -0.0760498 29.3446 0.0384712ZM17.859 26.5074C20.179 27.1087 21.6971 28.7549 22.141 31.1599C22.4561 32.8205 21.8116 34.7674 20.5371 36.0271C19.2768 37.3012 17.3291 37.9454 15.6679 37.6304C13.1187 37.158 11.3858 35.4259 10.9132 32.8778C10.5123 30.7591 11.6293 28.3398 13.534 27.1946C14.9375 26.35 16.3839 26.1209 17.859 26.5074ZM33.3259 26.5074C35.6459 27.1087 37.1639 28.7549 37.6079 31.1599C37.9229 32.8205 37.2785 34.7674 36.0039 36.0271C34.7436 37.3012 32.796 37.9454 31.1347 37.6304C28.5856 37.158 26.8527 35.4259 26.3801 32.8778C26.065 31.2315 26.7095 29.2703 27.9554 28.0249C29.4162 26.5647 31.3925 26.0064 33.3259 26.5074ZM48.7927 26.5074C51.1127 27.1087 52.6308 28.7549 53.0747 31.1599C53.5044 33.4504 52.1152 36.1416 49.967 37.1151C48.2628 37.9024 46.6588 37.9024 44.9546 37.1151C42.8065 36.1416 41.4173 33.4504 41.8469 31.1599C42.2766 28.7836 43.7946 27.123 46.0717 26.5074C47.0598 26.2498 47.7902 26.2498 48.7927 26.5074Z" fill={colorStatus(props.estatus)} />
      <path d="M15.6677 28.7106C14.0637 29.2259 13.1758 30.4141 13.1758 32.0174C13.1758 33.9786 14.5506 35.3672 16.5269 35.3672C18.5033 35.3672 19.8781 33.9786 19.8781 32.0174C19.8781 30.6575 19.248 29.5981 18.045 28.9826C17.4292 28.6676 16.2119 28.5245 15.6677 28.7106Z" fill={colorStatus(props.estatus)} />
      <path d="M31.1345 28.7106C29.5305 29.2259 28.6426 30.4141 28.6426 32.0174C28.6426 33.9786 30.0174 35.3672 31.9937 35.3672C33.97 35.3672 35.3449 33.9786 35.3449 32.0174C35.3449 30.6575 34.7147 29.5981 33.5118 28.9826C32.896 28.6676 31.6787 28.5245 31.1345 28.7106Z" fill={colorStatus(props.estatus)} />
      <path d="M46.6013 28.7106C44.9973 29.2259 44.1094 30.4141 44.1094 32.0174C44.1094 33.9786 45.4842 35.3672 47.4605 35.3672C49.4368 35.3672 50.8117 33.9786 50.8117 32.0174C50.8117 30.6575 50.1815 29.5981 48.9786 28.9826C48.3628 28.6676 47.1455 28.5245 46.6013 28.7106Z" fill={colorStatus(props.estatus)} />
    </svg>
  )
}

export default OtroStatus

